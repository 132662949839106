
import '../assets/css/App.css';
import Footer from '../components/Footer';
import * as React from "react";
import Stack from '@mui/material/Stack'
import Navbar from '../components/Navbar';
import { Fade, Paper, Slide, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import logokenniscentrum from '../assets/img/logokcvs.png';
import logovlaanderen from '../assets/img/Vlaanderen_verbeelding_werkt.png';
import { Container } from '@mui/system';
import CalculateIcon from '@mui/icons-material/Calculate';
import Fab from '@mui/material/Fab';


function Start() {

    const navigate = useNavigate();

    const handleSubmit = () => {

        navigate('/simulatie')

    }




    return (
        <div className="App">

            <Stack spacing={8}>
                <Navbar />
                <Fade in={true}>
                <Container maxWidth="100%" >
                    <Container maxWidth="lg">
                        {/* <Paper> */}
                        <Typography variant="h1" gutterBottom>
                            Aan de slag met energiedelen
                        </Typography>
                    </Container>
                </Container>
                </Fade>

                <Slide direction='right' in={true}>
                <Container maxWidth="100%" sx={{ backgroundColor: "#1976d2", padding: "5%" }}>
                    <Container maxWidth="lg">
                        <Typography variant="subtitle2" gutterBottom color="white">
                            Bereken het potentieel van energiedelen in jouw gebouw met deze simulatietool!
                        </Typography>
                        <Typography variant="body1" color="white">
                            <br></br>
                            Het model berekent per kwartier de hoeveelheid afgenomen,
                            geïnjecteerde, en gedeelde energie voor elke woonunit (of voor de gemene delen). Op basis van deze energetische analyse wordt vervolgens
                            per kwartier een economische analyse gemaakt.
                        </Typography>
                        {/* </Paper> */}
                    </Container>
                </Container>
                </Slide>

                <Fade in={true}>
                <Typography variant='h4'>3 Scenario's:</Typography>
                </Fade>

                <Fade in={true}>
                <Container maxWidth="100%">
                    <Container maxWidth="lg">
                        <Stack direction="row" spacing={3}>
                            <Paper elevation={5} sx={{ padding: "2%" }}>
                                <Typography variant='h5' gutterBottom>Individuele installaties</Typography>
                                <Typography variant="body1">
                                    In dit scenario is elke unit afzonderlijk aangesloten op de zonnepaneel installatie.
                                </Typography>
                            </Paper>
                            <Paper elevation={5} sx={{ padding: "2%" }}>

                                <Typography variant='h5' gutterBottom>Gemeenschappelijk</Typography>
                                <Typography variant="body1">
                                    In dit scenario is de volledige zonnepaneel installatie aangesloten
                                    op de gemene delen. De rest energie wordt geïnjecteerd op het net
                                    waarna de financiële opbrengsten herverdeeld kunnen worden tussen de
                                    deelnemers.
                                </Typography>
                            </Paper>
                            <Paper elevation={5} sx={{ padding: "2%" }}>

                                <Typography variant='h5' gutterBottom>Energiedelen</Typography>
                                <Typography variant="body1">
                                    Energiedelen: In dit scenario is de volledige zonnepaneel installatie aangesloten
                                    op de gemene delen. De rest energie wordt nadien herverdeeld tussen
                                    de deelnemers waardoor hun energiekost daalt.
                                </Typography>
                            </Paper>
                        </Stack>
                    </Container>
                </Container>
                </Fade>

                <Slide direction='right' in={true}>
                <Container maxWidth="100%" sx={{ backgroundColor: "#1976d2", padding: "5%" }}>
                    <Container maxWidth="lg">
                        {/* <Paper> */}
                        <Typography variant="body1" color="white">
                            Als eindresultaat van de simulatie krijg je de samenvatting van de energetisch en economische analyse van de drie verschillende scenario's
                            terug om zo te onderzoeken wat voor jouw gebouw de verschillende mogelijkheden zijn.

                            Voor je aan het proces kan beginnen heb je volgende informatie over het gebouw nodig.
                        </Typography>
                        {/* </Paper> */}
                    </Container>
                </Container>
                </Slide>

                <Fade in={true}>
                <Typography variant='h4'>Nodige informatie:</Typography>
                </Fade>

                <Fade in={true}>
                <Container maxWidth="100%">
                    <Container maxWidth="lg">
                        <Stack direction="row" spacing={3}>
                            <Paper elevation={5} sx={{ padding: "2%" }}>
                                <Typography variant="body1">
                                    Het potentieel voor hernieuwbare energie op of rond het gebouw (neem zeker eens een kijkje op de zonnekaart!)
                                </Typography>
                            </Paper>
                            <Paper elevation={5} sx={{ padding: "2%" }}>
                                <Typography variant="body1">
                                    Het aantal woonunits en gemene delen die zich in het gebouw bevinden
                                </Typography>
                            </Paper>
                            <Paper elevation={5} sx={{ padding: "2%" }}>
                                <Typography variant="body1">
                                    Informatie over deze woonunits zoals type verwarming, aantal laadpunten of aantal bewoners
                                </Typography>
                            </Paper>
                        </Stack>
                    </Container>
                </Container>
                </Fade>


                <Container maxWidth="100%" sx={{ backgroundColor: "#1976d2", padding: "5%" }}>
                    <Container maxWidth="lg">
                        {/* <Paper> */}
                        {/* <Typography variant='h4' color="white">Simuleer</Typography> */}
                        <Typography variant="body1" color="white" gutterBottom>
                            Heb je de nodige informatie bij de hand? Start de simulatie door in volgende stap de gegevens in te vullen.
                        </Typography>
                        <Typography variant="body1" color="white">
                            <Fab color="secondary" aria-label="to-simulation" variant='extended' size='large' onClick={handleSubmit} sx={{color:"white"}}>
                                <CalculateIcon />
                                Naar de simulatie!
                            </Fab>
                        </Typography>
                        {/* </Paper> */}
                    </Container>
                </Container>

                <Fade in={true}>
                <Container maxWidth="100%">
                    <Container maxWidth="sm">
                        <Typography><img src={logokenniscentrum} width="80%" alt="Logo Kenniscentrum Vlaamse Steden" /></Typography>
                        <Typography><img src={logovlaanderen} width="80%" alt="Logo Vlaanderen Verbeelding Werkt" /></Typography>
                    </Container>
                </Container>
                </Fade>
                <Typography gutterBottom variant="caption">
                    De berekeningen in deze simulatietool worden zo nauwkeurig mogelijk samengesteld op basis van aannames en gegevens die worden opgevraagd in de volgende stap. Aan deze resultaten kunt u geen rechten ontlenen
                </Typography>
                <Typography gutterBottom variant="caption" >
                    Een publicatie van Th!nk E in opdracht van de Interlokale vereniging Kenniscentrum Vlaamse Steden. Met steun van het Agentschap Binnenlands Bestuur
                </Typography>
                <Footer />
            </Stack>

        </div>
    );


}

export default Start;