import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CustomTextField from './CustomTextField';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Grid } from '@mui/material';


export default function SettingsField({ onSettingsChange }) {
    return (
        <Card variant='outlined' sx={{ mb: '5%', display: 'block' }}>


            <Typography variant='h6'>Algemene instellingen</Typography>
            <Typography variant='subtitle1'>Voeg hier de gegevens in die gelden voor het hele gebouw.</Typography>
            <Grid container spacing={2} justifyContent='space-evenly'>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Card sx={{ margin: '10px', padding: '5px 10px 1.5em 10px', minWidth: '100px' }}>
                        <Typography sx={{ fontSize: '1.1em' }}>
                            Piekvermogen zonnepanelen
                            <Tooltip title="De maximale hoeveelheid hernieuwbare energie die kan geinstalleerd worden op of rond het gebouw. Raadpleeg de zonnekaart om een nauwkeurige inschatting te krijgen van het piekvermogen van jouw gebouw https://www.vlaanderen.be/bouwen-wonen-en-energie/groene-energie/zonnekaart-is-uw-dak-geschikt-voor-zonneboiler-of-zonnepanelen">
                            <IconButton>
                                <InfoIcon />
                            </IconButton>
                            </Tooltip>
                        </Typography>
                        
                        

                        <CustomTextField
                            cardID={null}
                            textFieldID="totalSolarAmount"
                            onCardChange={null}
                            onSettingsChange={onSettingsChange}
                            adornment="kWp"
                            defaultValue="10"
                            validation="float" />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>

                    <Card sx={{ margin: '10px', padding: '5px 10px 1.5em 10px' }}>
                        <Typography type="body1" sx={{ fontSize: '1.1em' }}>Prijs zonnepanelen <small>(Excl btw)</small>
                        <Tooltip title="Raadpleeg een installateur voor een nauwkeurige prijs of laat de default waarden staan voor een schatting">
                            <IconButton>
                                <InfoIcon />
                            </IconButton>
                            </Tooltip>
                        </Typography>
                        <CustomTextField
                            cardID={null}
                            textFieldID="totalSolarPrice"
                            onCardChange={null}
                            onSettingsChange={onSettingsChange}
                            adornment="€/kWp"
                            defaultValue="1500"
                            validation="float" />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Card sx={{ margin: '10px', padding: '5px 10px 1.5em 10px' }}>
                        <Typography type="body1"sx={{ fontSize: '1.1em' }} >
                            Administratiekost
                            <Tooltip title="Deze kost bestaat uit de extra aanrekening door de energieleveranciers als ook de beheerskost van de gemeenschap (bijvoorbeeld door syndicus)">
                            <IconButton>
                                <InfoIcon />
                            </IconButton>
                            </Tooltip>
                        </Typography>
                        {/* <Typography type="subtitle"sx={{ fontSize: '0.9em' }} >Deze kost bestaat uit de extra aanrekening door de energieleveranciers en de beheerskost van het energiedelen</Typography> */}
                        <CustomTextField
                            cardID={null}
                            textFieldID="adminCost"
                            onCardChange={null}
                            onSettingsChange={onSettingsChange}
                            adornment="€/jaar"
                            defaultValue="100"
                            validation="float" />
                    </Card>
                </Grid>
            </Grid>
        </Card >
    );
}