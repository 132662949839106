import * as React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';





export default function CustomTextField({ cardID, textFieldID, onCardChange, onSettingsChange, adornment, defaultValue, validation, label }) {
    // eslint-disable-next-line
    const [value, setValue] = React.useState({defaultValue});
    const [validValue, setValidValue] = React.useState(true);
    const [helperText, sethelperText] = React.useState("");
    // eslint-disable-next-line
    const [validationMethod, setValidationMethod] = React.useState(validation)

    const isInteger = (str) => {
        return /^\d+$/.test(str);
    }

    const isFloat = (str) => {
        if (isInteger(str)){ return true} 
        let regex = /[0-9]*\.[0-9]+/i;
        return regex.test(str);
    }

    const isValidName = (str) => {
        var letters = /^[0-9a-zA-Z\s]+$/;
        if (!letters.test(str)) {
            sethelperText("Gebruik enkel cijfers en letters")
            return false
        }

        if (str.length > 20) {
            sethelperText("De naam mag niet langer zijn dan 20 tekens")
            return false
        }
        return true
    }

    const handleTextFieldChange = (event) => {

        const str = event.target.value;
        // console.log("HUH?", event.target.value)
        // console.log(validationMethod);

        switch (validationMethod) {
            case "float":
                const isFloatNumber = isFloat(str);
                sethelperText("Voer een positief getal in")
                setValidValue(isFloatNumber);
                let floatNumber = 0;
                isFloatNumber ? floatNumber = parseFloat(str) : floatNumber = 0
                setValue(floatNumber)
                // console.log("HOOK:", value)
                // console.log("LOCAL: ", floatNumber)
                if(cardID != null){
                    onCardChange(cardID, textFieldID, floatNumber)
                }else{
                    onSettingsChange(textFieldID, floatNumber)
                }
                
                break;

            case "name":
                const isName = isValidName(str);
                setValidValue(isName);
                let name = "unit"
                isName ? name = str : name = "unit"
                if(cardID != null){
                    onCardChange(cardID, textFieldID, name)
                }else{
                    onSettingsChange(textFieldID, name)
                }
                break;


            default:
                const isIntNumber = isInteger(str);
                sethelperText("Voer een positief geheel getal in")
                setValidValue(isIntNumber);
                let intNumber = 0
                isIntNumber ? intNumber = parseInt(str) : intNumber = 0;
                setValue(intNumber);
                onCardChange(cardID, textFieldID, intNumber)
                break;
        }
        
    }

    return (
        <TextField
            id= {textFieldID}
            placeholder={defaultValue}
            onChange={handleTextFieldChange}
            error={!validValue}
            helperText={validValue ? null : helperText}
            label={label}
            InputProps={{
                endAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
            }}
            sx={{ mb: '8px'}}
        // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        // startAdornment={<InputAdornment position="end">units</InputAdornment>}
        />
    );
}