import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';

import { CardActionArea } from '@mui/material';

export default function AddUnitCard({ addNewCard, lastID, setLastID }) {
    // const [fade, setFade] = React.useState(true);

    const handleOnClick = (event) =>  {
        addNewCard({
            id: lastID,
            name: "Unit " + lastID,
            telsysteem: "enkelvoudig",
            familyMembers: "2",
            unitType: "appartement",
            heatingType: "gas",
            //solarAmount: "0",
            divisionKeyShare: "0",
            energyComponent: "0.25",
            dayPrice: "0.4",
            dayInjectionPrice: "0.1",
            nightPrice: "0.3",
            nightInjectionPrice: "0.05",
            evAmount: "0",
            numberOfUnits: "1",
            // prijsPerKWP: "0",
            // jaarlijkseAdminCost: "0",
            // kWp: "0"
        });

        setLastID(lastID + 1);
    }


    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card variant='outlined'>
                <CardActionArea onClick={handleOnClick}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Voeg een type unit toe
                        </Typography>
                        <AddIcon />
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}