// import logo from './assets/img/logo.svg';
import './assets/css/App.css';
import Scenario1 from './pages/Scenario1';
import Scenario2 from './pages/Scenario2';
import * as React from "react";
import Scenario3 from './pages/Scenario3';
import Home from './pages/Home'
import Start from './pages/Start'
import { BrowserRouter, Routes, Route } from "react-router-dom";



function App() {



  return (
    <div className="App">
      <BrowserRouter>
      
      <Routes>
        <Route path='/simulatie' element={<Home />} />
        <Route path='/' element={<Start />} />
        <Route path='/scenario1' element={<Scenario1/>}></Route>
        <Route path='/scenario2' element={<Scenario2/>}></Route>
        <Route path='/scenario3' element={<Scenario3/>}></Route>

      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
