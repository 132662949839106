import * as React from 'react';
import Fab from '@mui/material/Fab';


export default function removeUnitCard({setVisible, ID, setID, removeElement}) {

    // const Button = styled.button`
    // background-color: #e8e8e8;
    // color: black;
    // padding: 2px 10px 2px 10px;
    // justify-content: right;
    // border: 1px solid transparent;
    // border-radius: 2px;
    // border-color: grey;`;

    // eslint-disable-next-line
    const div = () => document.getElementById('blob');

    const check = () => {
        removeElement(ID);
    }

    return (
        <div>
            <Fab color="primary" variant="extended" size='small' onClick={check} align='right'>
                    X
            </Fab>
        </div>

    )
                    
}

