import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";


export default function Navbar() {
  
  
  // const refreshDutch = () => {
  //   document.getElementById('button1').style.textDecoration = "underline"
  //   document.getElementById('button2').style.textDecoration = "none"
  // }
  // const refreshEnglish = () => {
  //   document.getElementById('button2').style.textDecoration = "underline"
  //   document.getElementById('button1').style.textDecoration = "none"
   
  // }

  


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Button

            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, marginTop:"5px" }}
            component={Link} to="https://www.think-e.be/"
            
          >
            <img src='https://www.think-e.be/themes/custom/think_e/assets/images/logo/think-e--logo.gif' alt='thinkelogo' width="70" height="22"></img>
          </Button>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Optishare
          </Typography>
      
          {/* <Box sx={{display:"flex", alignSelf:"center"}}>
            <Button onClick={refreshDutch} id='button1' sx={{fontSize:"12px",height:"20px",color:"white",borderRight:"1px solid white", borderRadius:"0"}}>NL</Button>
            <Button onClick={refreshEnglish} id='button2' sx={{fontSize:"12px",height:"20px",color:"white"}}>EN</Button>
          </Box> */}
    
          
        
       
          <Button color="inherit" component={Link} to="/">Home</Button>
          {/* <Button color="inherit" component={Link} to="/results">Results</Button> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}