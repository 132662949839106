import "../assets/css/App.css";
import * as React from "react";
import { useEffect } from "react";
import Footer from "../components/Footer";
import Stack from "@mui/material/Stack";
import Navbar from "../components/Navbar";
import { Container } from "@mui/system";
import {useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import "../assets/css/styles.css";
import Box from "@mui/material/Box";
import { Card, List, ListItem, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Fab from '@mui/material/Fab';
import SolarPowerSharpIcon from '@mui/icons-material/SolarPowerSharp';
import ElevatorSharpIcon from '@mui/icons-material/ElevatorSharp';
import ApartmentSharpIcon from '@mui/icons-material/ApartmentSharp';
import OutletSharpIcon from '@mui/icons-material/OutletSharp';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


function Scenario1() {
  const navigate = useNavigate();
  const location = useLocation();
  const json = location.state
  const [checked, setChecked] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);


  const calcUnits = Object.keys(json).map((row) =>
        ({
            Investment: json[row]["Type"]
        })
        
    );
  
  const columns = [ 
      {field: "Unit", headerName: "Unit", width:140, renderCell: (cellValues) => {
        return (
          <div
            style={{
              color: "black",
              borderBottom:"1px solid black",
              fontSize: 18,
            }}
          >
            {cellValues.value}
          </div>
        );
      } },
      {field: "Consumption", headerName: "Verbruik (kWh)", width:140, sortable: false, fontFamily:"Roboto"},
      {field: "Production", headerName: "Productie (kWh)", width:140, sortable: false},
      {field: "Selfconsumption", headerName: "Zelfconsumptie (%)", width:140, sortable: false},
      {field: "Investment", headerName: "Investering (€)", width:140, sortable: false},
      {field: "Costsavedenergybill", headerName: "Vermindering energiefactuur zonnepanelen (€)", width:140, sortable: false},
      {field: "Injectionearnings", headerName: "Injectieopbrengst (€)", width:140, sortable: false},
      {field: "Totalyearlygains", headerName: "Jaarlijks voordeel (€)", width:140, sortable: false},
      {field: "Totalcostofownership20", headerName: "Totale opbrengst na 20 jaar (€)", width:140, sortable: false}];

    // console.log(json)

  const rows = Object.keys(json).map((row) =>
          ({          
                  id: json[row]["ID"],
                  Unit: json[row]["IV Name"],
                  Consumption: json[row]["IV Consumption"].toFixed(),
                  Production: json[row]["IV Production"].toFixed(),
                  Selfconsumption: (json[row]["IV Self consumption"]*100).toFixed(),
                  Investment: json[row]["IV Investment"].toFixed(),
                  Costsavedenergybill: json[row]["IV Cost saved energy bill"].toFixed(),
                  Injectionearnings: json[row]["IV Injection earnings"].toFixed(),
                  Totalyearlygains: json[row]["IV Total yearly gains"].toFixed(),
                  Totalcostofownership20: json[row]["IV Total Cost Of Ownership 20"].toFixed(),
              })
              
          );





  function CustomToolbar() {
      return (
          <GridToolbarContainer>
          <GridToolbarExport />
          </GridToolbarContainer>
      );
  }

  var listAppartments = []
  var listSharedUnits = []
  var sumAppartmentProduction = 0;
  var sumSharedProduction = 0;
  var sumADInjection =0;
  var sumAppInjection=0;
  var sumADInjectionEarnings = 0;
  var sumAppInjectionEarnings = 0;
  var sumAppNetconsumption = 0;
  var sumADNetconsumption = 0;
  var sumAppCostWithSolar = 0;
  var sumADCostWithSolar = 0;
  for(var unit=0; unit<calcUnits.length; unit++){
    if((Object.values(Object.values(calcUnits)[unit])[0])==="appartement"){
      listAppartments.push(Object.values(json)[unit])
      
    }
    if((Object.values(Object.values(calcUnits)[unit])[0])==="gemeenschappelijk"){
      listSharedUnits.push(Object.values(json)[unit])
      
    }
  }


  // (Object.values(json)[2])[0]+=1
 
    // console.log(json)

 
  

  for(var production=0; production<listAppartments.length; production++){
      
      sumAppartmentProduction+=listAppartments[production]['IV Production']
  }
  for(var production2=0; production2<listSharedUnits.length; production2++){
      
    sumSharedProduction+=listSharedUnits[production2]['IV Production']
}

for(var injection=0; injection<listAppartments.length;injection++){
  sumAppInjection+=listAppartments[injection]['IV Injection']
}

for(var injection2=0; injection2<listSharedUnits.length;injection2++){
  sumADInjection+=listSharedUnits[injection2]['IV Injection']
}

for(var injectionEarning=0; injectionEarning<listAppartments.length;injectionEarning++){
  sumAppInjectionEarnings+=listAppartments[injectionEarning]['IV Injection earnings']
}

for(var injectionEarning2=0; injectionEarning2<listSharedUnits.length;injectionEarning2++){
  sumADInjectionEarnings+=listSharedUnits[injectionEarning2]['IV Injection earnings']
}

for(var consumption=0; consumption<listAppartments.length;consumption++){
  sumAppNetconsumption+=listAppartments[consumption]['IV Consumption']
}

for(var consumption2=0; consumption2<listSharedUnits.length;consumption2++){
  sumADNetconsumption+=listSharedUnits[consumption2]['IV Consumption']
}

for(var costwithsolar=0; costwithsolar<listAppartments.length;costwithsolar++){
  sumAppCostWithSolar+=listAppartments[costwithsolar]['IV Cost with solar']
}

for(var costwithsolar2=0; costwithsolar2<listSharedUnits.length;costwithsolar2++){
  sumADCostWithSolar+=listSharedUnits[costwithsolar2]['IV Cost with solar']
}


// const useStyles = makeStyles((theme) =>
//   createStyles({
//     root: {
//       '& div[data-rowIndex][role="row"]:nth-of-type(2n)': {
//         backgroundColor: "blue",
//         fontSize: 18,
//       },
//       "& .MuiDataGrid-renderingZone":{
//           "& .MuiDataGrid-row--lastVisible":{
//             backgroundColor: "blue"
//           }
        
//       }
//     }
//   })
// );


  // for(var i = 0; i<calcUnits.length; i++){
  //   listUnits.push(Object.values(calcUnits[i])[0])
  // }





    //console.log(Object.values(rows[rows.length - 1]))

    //console.log(Object.values(rows[rows.length - 1])[0])

  

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleChange2 = () => {
    setChecked2((prev) => !prev);
  };
  const handleChange3 = () => {
    setChecked3((prev) => !prev);
  };

  const handleChange4 = () => {
    setChecked2((prev) => !prev);
    setChecked3((prev) => !prev);
  };

  const handleChange6 = () => {
    setChecked((prev) => !prev);
    setChecked2((prev) => !prev);
    setChecked3((prev) => !prev);
  };


  const icon1 = (
    <Paper sx={{ m: 1,borderRadius:"30px", backgroundColor:"lightblue", height:"100px", width:"250px"}} elevation={4}>
      <Box sx={{marginTop:"20px",justifyContent:"center"}}>
              <Typography id="popup1Txt1">Productie (App)</Typography>
              </Box >
              <Box sx={{justifyContent:"center"}}> 
              <Typography id="popup1Txt2">{sumAppartmentProduction.toFixed() + " kWh"}</Typography>
              </Box >
    </Paper>
  );
  const icon2 = (
    <Paper sx={{ m: 1, borderRadius:"30px", backgroundColor:"lightblue", height:"100px", width:"250px"}} elevation={4}>
      <Box sx={{marginTop:"20px",justifyContent:"center"}}>
              <Typography id="popup2Txt1">Productie (AD)</Typography>
              </Box >
              <Box sx={{justifyContent:"center"}}> 
              <Typography id="popup2Txt2">{sumSharedProduction.toFixed() + " kWh"}</Typography>
              </Box >
    </Paper>
  );
  const icon3 = (
    <Paper sx={{ m: 1,borderRadius:"30px", backgroundColor:"lightblue",height:"100px", textAlign:"left", width:"200px"}} elevation={4}>
              <Box sx={{marginTop:"20px",marginLeft:"10px", justifySelf:"left"}}> 
              <Typography id="popup3Txt1">Injectie (AD)</Typography>
              </Box >
              <ul style={{marginLeft:"50px", textAlign:"left", display:"inline-block"}}> 
              <li id="popup3Txt2">{sumADInjection.toFixed() + " kWh"}</li>
              <li id="popup3Txt3">{sumADInjectionEarnings.toFixed() + " €"}</li>
              </ul >
    </Paper>
  );

  const icon4 = (
    <Paper sx={{ m: 1, borderRadius:"30px",bgcolor:"#FAA0A0", height:"100px", textAlign:"left", width:"200px"}} elevation={4}>
              <Box sx={{marginTop:"20px",marginLeft:"10px",justifySelf:"left"}} id="popup4"> 
              <Typography id="popup4Txt1">Verbruik (AD)</Typography>
              </Box >
              <ul  style={{marginLeft:"50px", textAlign:"left", display:"inline-block"}}> 
              <li id="popup4Txt2">{sumADCostWithSolar.toFixed() + " €"}</li>
              <li id="popup4Txt3">{sumADNetconsumption.toFixed() + " kWh"}</li>
              </ul >
    </Paper>
  );
  const icon5 = (
    <Paper  sx={{ m: 1,borderRadius:"30px", backgroundColor:"lightblue", height:"100px", textAlign:"left", width:"200px"}} elevation={4}>
      <Box sx={{marginTop:"20px",marginLeft:"10px", justifySelf:"left"}}> 
              <Typography  id="popup5Txt1">Injectie (App)</Typography>
              </Box >
              <ul  style={{marginLeft:"50px", textAlign:"left", display:"inline-block"}}> 
              <li  id="popup5Txt2">{sumAppInjection.toFixed() + " kWh"}</li>
              <li  id="popup5Txt3">{sumAppInjectionEarnings.toFixed() + " €"}</li>
              </ul >
    </Paper>
  );
  const icon6 = (
    <Paper sx={{ m: 1, borderRadius:"30px",bgcolor:"#FAA0A0", height:"100px", textAlign:"left", width:"200px"}} elevation={4}>
      <Box sx={{marginTop:"20px",marginLeft:"10px",justifySelf:"left"}}> 
              <Typography  id="popup6Txt1">Verbruik (App)</Typography>
              </Box >
              <ul  style={{marginLeft:"50px", textAlign:"left", display:"inline-block"}}> 
              <li id="popup6Txt2">{": " + sumAppCostWithSolar.toFixed() + " €"}</li>
              <li   id="popup6Txt3">{": " + sumAppNetconsumption.toFixed() + " kWh"}</li>
              </ul >
    </Paper>
  );




  const toScenario1 = () => {
    navigate('/scenario1', {state: json});
  }
  const toScenario2 = () => {
    navigate('/scenario2', {state: json});
  }
  const toScenario3 = () => {
    navigate('/scenario3', {state: json});
  }


  // const classes = useStyles()
  return (
    <div className="App">
      {/* <Banner></Banner> */}
      <Stack spacing={3}>
        <Navbar />

        <Container maxWidth="100%" >
        <Container maxWidth="lg">
            {/* <Paper> */}
            <Typography variant="h4" gutterBottom>
                Samenvatting
            </Typography>
        </Container>
        </Container>

        <Container maxWidth="100%">
        <Container maxWidth="lg">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">Individueel</TableCell>
                <TableCell align="right">Gemeenschappelijk</TableCell>
                <TableCell align="right">Energiedelen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                  <TableRow
                    key={1}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Investering
                    </TableCell>
                    <TableCell align="right">€ {json["Total"]["IV Investment"].toFixed()}</TableCell>
                    <TableCell align="right">€ {json["Total"]["Investment"].toFixed() }</TableCell>
                    <TableCell align="right">€ {json["Total"]["TR Investment"].toFixed()}</TableCell>
                  </TableRow>


                  <TableRow
                    key={2}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Jaarlijkse opbrengst
                    </TableCell>
                    <TableCell align="right">€ {json["Total"]["IV Total yearly gains"].toFixed()}</TableCell>
                    <TableCell align="right">€ {json["Total"]["Total yearly gains"].toFixed()}</TableCell>
                    <TableCell align="right">€ {json["Total"]["TR Total yearly gains"].toFixed()}</TableCell>
                  </TableRow>

                  <TableRow
                    key={3}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Totale opbrengst na 20 jaar
                    </TableCell>
                    <TableCell align="right">€ {json["Total"]["IV Total Cost Of Ownership 20"].toFixed()}</TableCell>
                    <TableCell align="right">€ {json["Total"]["Total Cost Of Ownership 20"].toFixed()}</TableCell>
                    <TableCell align="right">€ {json["Total"]["TR Total Cost Of Ownership 20"].toFixed()}</TableCell>
                  </TableRow>

                
            </TableBody>
          </Table>
        </TableContainer>
        </Container>
        </Container>


        <Container
          sx={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >

          <Container sx={{display:"flex", justifyContent:"center", gap: "2em", margin:"20px 15px 20px 0"}}>
          <Fab color="secondary" aria-label="simulate" variant='extended' size='large' onClick={toScenario1} >
            Individueel
          </Fab>
          <Fab color="white" aria-label="simulate" variant='extended' size='large' onClick={toScenario2} >
            Gemeenschappelijk
          </Fab>
          <Fab color="white" aria-label="simulate" variant='extended' size='large' onClick={toScenario3} >
            Energiedelen
          </Fab>
        </Container>
        

          <Container maxWidth="100%" >
          <Container maxWidth="lg">
              {/* <Paper> */}
              <Typography variant="h4" gutterBottom sx={{marginTop:"5%"}}>
                  Beschrijving scenario
              </Typography>
          </Container>
          </Container>


          

          <Typography
            variant="subtitle1"
            align="left"
            paragraph={true}
            gutterBottom={true}
          >
            In dit scenario is elke unit afzonderlijk aangesloten op de
            zonnepaneel installatie. Alle geproduceerde energie kan achter de
            meter worden gebruikt, echter stijgt de investeringskost hierdoor.
          </Typography>

          <Container maxWidth="100%" >
          <Container maxWidth="lg">
            <Stack direction="row" spacing={3} justifyContent="space-evenly">
              <Paper elevation={5} sx={{ padding: "2%" }}>
                <Typography variant="h6">Voordelen</Typography>
                <Typography variant="body1">
                    <ListItem>De geproduceerde energie wordt achter de meter verbruikt, de energiefactuur van de deelnemende units daalt.</ListItem>
                    <ListItem>Er worden geen distributietarieven aangerekend.</ListItem>
                    <ListItem>Elke unit krijgt zijn eigen injectieopbrengsten rechtstreeks.</ListItem>
                    <ListItem>Dit is een economisch rendabele optie bij een groot dakoppervlak en weinig woonunits.</ListItem>
                </Typography>
              </Paper>
              <Paper elevation={5} sx={{ padding: "2%" }}>
                <Typography variant="h6">Nadelen</Typography>
                <Typography variant="body1">
                  <List>
                    <ListItem>Een hogere investeringskost door extra materiaal (iedereen heeft een eigen omvormer).</ListItem>
                    <ListItem>Het extra nodige materiaal zorgt ervoor dat dit niet de meest ecologische oplossing is.</ListItem>
                    <ListItem>Er is niet altijd voldoende fysieke ruimte om alle nodige kabels en omvormers te plaatsen.</ListItem>
                    <ListItem>Dit scenario houdt geen rekening met mogelijke uitbreiding in verbruik van gemene delene (het is niet future proof).</ListItem>
                    <ListItem>Wanneer het dakoppervlak te klein is ten opzichte van het aantal woonunits, is dit economisch en praktisch geen gunstig scenario.</ListItem>
                  </List>
                </Typography>
              </Paper>

            </Stack>
          </Container>
          </Container>

          <Container maxWidth="100%" >
          <Container maxWidth="lg">
              {/* <Paper> */}
              <Typography variant="h4" gutterBottom sx={{marginTop:"5%"}}>
                  Resultaten
              </Typography>
          </Container>
          </Container>
    
          <div className="DataGrid"  style={{height: 520, width: '100%' }}> 
          <DataGrid
            // className={classes.root}
            disableColumnMenu
            hideFooterPagination
            hideFooterSelectedRowCount
            rows={rows}
            columns={columns}
            slots={{
              toolbar: CustomToolbar,
            }}
            
            
          
          />
          </div>
          
          <FormControlLabel id="showAllLabel" sx={{marginTop:"2em", position:"sticky", top:"0", zIndex:"100"}}
            control={<Switch checked1={checked} checked2={checked2} checked3={checked3} onChange={handleChange6} />}
            label="Toon alle info"
          />
          
          <Card
            elevation={5}
            className="card1"
            sx={{
              width: "100%",
              padding: "3em",
              borderRadius: "30px",
              marginBottom: "2em",
              display: "grid",
              alignSelf: "center",
              justifyContent: "space-evenly",
              gridTemplateRows: "100px 150px;",
            }}
          >
             
            <div className="containersIcons" style={{
                gridColumn: "3", zIndex:"1"}}>
            <Typography>Zonnepaneel installaties</Typography>
            <Button checked={checked} onClick={handleChange} sx={{height: { xs: 103, md: 147 },
                width: { xs: 103, md: 150 },boxShadow: 5, backgroundColor: "lightblue",borderRadius: "30%",}}>
            <SolarPowerSharpIcon sx={{ height: { xs: 80, md: 100 },
                width: { xs: 80, md: 100 }, borderRadius: "30%",}}></SolarPowerSharpIcon>
            </Button>
            </div>

            <Grow className="Pop6" 
              in={checked}
              style={{gridColumn: "5", gridRow: "1" , transformOrigin: '0 0 0'}}
              {...(checked ? { timeout: 1000 } : {})}
            >
            {icon1}
            </Grow>
            <Grow className="Pop6" 
              in={checked}
              style={{gridColumn: "1", gridRow: "1" , transformOrigin: '0 0 0'}}
              {...(checked ? { timeout: 500 } : {})}
            >
            {icon2}
            </Grow>



            <Box
              checked={checked} onClick={handleChange}
              
              sx={{
                
                alignSelf: "center",
                gridColumn: "4",
                gridRow: "2",
              }}
              
            >
              <div id="pijl1">
              <Box id="arrowAnim">
                <div className="arrowSliding">
                  <div name="arrowId1" className="arrow"></div>
                </div>
                <div className="arrowSliding delay1">
                  <div name="arrowId1" className="arrow"></div>
                </div>
                <div className="arrowSliding delay2">
                  <div name="arrowId1" className="arrow"></div>
                </div>
                <div className="arrowSliding delay3">
                  <div name="arrowId1" className="arrow"></div>
                </div>
                <div className="arrowSliding delay4">
                  <div name="arrowId1" className="arrow"></div>
                </div>
                <div className="arrowSliding delay5">
                  <div name="arrowId1" className="arrow"></div>
                </div>
              </Box>
              </div>
            </Box>
              
              
            

            <div className="Appartementen" style={{
                gridColumn: "5",
                gridRow: "3", zIndex:"1"}}>
                 
                  <Box className="appBox">
                <Typography>Woonunits</Typography>
                
                <Button checked={checked3} onClick={handleChange3} sx={{height: { xs: 103, md: 147 },
                width: { xs: 103, md: 150 },boxShadow: 5, backgroundColor: "lightblue",borderRadius: "30%",}}>
            <ApartmentSharpIcon  sx={{ height: { xs: 80, md: 100 },
                width: { xs: 80, md: 100 }, borderRadius: "30%",}}></ApartmentSharpIcon>
            </Button>
            
            </Box>
            </div>

            <Box sx={{gridColumn: "5", gridRow: "5" ,display:"flex", flexDirection:"column"}}>
            <Grow className="Pop6" 
              in={checked3}
              style={{transformOrigin: '0 0 0'}}
              {...(checked3 ? { timeout: 500 } : {})}
            >
            {icon5}
            </Grow>
            <Grow className="Pop6" 
              in={checked3}
              style={{transformOrigin: '0 0 0'}}
              {...(checked3 ? { timeout: 1000 } : {})}
            >
            {icon6}
            </Grow>
            </Box>

            
          
            <Box sx={{ alignSelf: "center", gridColumn: "2", gridRow: "2"}}>
            <div id="pijl2">
            <Box checked={checked} onClick={handleChange}>
              <div id="arrowAnim2">
                <div className="arrowSliding">
                  <div name="arrowId2" className="arrow"></div>
                </div>
                <div className="arrowSliding delay1">
                  <div name="arrowId2" className="arrow"></div>
                </div>
                <div className="arrowSliding delay2">
                  <div name="arrowId2" className="arrow"></div>
                </div>
                <div className="arrowSliding delay3">
                  <div name="arrowId2" className="arrow"></div>
                </div>
                <div className="arrowSliding delay4">
                  <div name="arrowId2" className="arrow"></div>
                </div>
                <div className="arrowSliding delay5">
                  <div name="arrowId2" className="arrow"></div>
                </div>
              </div>
              </Box>
              </div>
              </Box>
            <div className="AlgemeneDelen" style={{
                gridColumn: "1",
                gridRow: "3", zIndex:"1", width:"15vw"}}>
                <Box className="ADBox" >
            <Typography>Algemene Delen</Typography>
            <Button checked={checked2} onClick={handleChange2} sx={{height: { xs: 103, md: 147 },
                width: { xs: 103, md: 150 }, boxShadow: 5, backgroundColor: "lightblue",borderRadius: "30%",}}>
            <ElevatorSharpIcon sx={{ height: { xs: 80, md: 100 },
                width: { xs: 80, md: 100 }, borderRadius: "30%",}}></ElevatorSharpIcon>
            </Button>
            </Box>
            </div>
            <Box  sx={{gridColumn: "1", gridRow: "5" ,display:"flex", flexDirection:"column"}}>
            <Grow className="Pop6" 
              in={checked2}
              style={{transformOrigin: '0 0 0'}}
              {...(checked2 ? { timeout: 500 } : {})}
            >
            {icon3}
            </Grow>
            <Grow className="Pop6" 
              in={checked2}
              style={{gridColumn: "1", gridRow: "5" , transformOrigin: '0 0 0'}}
              {...(checked2 ? { timeout: 1000 } : {})}
            >
            {icon4}
            </Grow>
            </Box>


            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                gridColumn: "2",
                gridRow: "4",
                
              }}
            >
              <div id="pijl3">
              <Box checked={checked2} onClick={handleChange2}>
              
                <div id="arrowAnim3">
                  <div className="arrowSliding">
                    <div name="arrowId3" className="arrow"></div>
                  </div>
                  <div className="arrowSliding delay1">
                    <div name="arrowId3" className="arrow"></div>
                  </div>
                  <div className="arrowSliding delay2">
                    <div name="arrowId3" className="arrow"></div>
                  </div>
                  <div className="arrowSliding delay3">
                    <div name="arrowId3" className="arrow"></div>
                  </div>
                  <div className="arrowSliding delay4">
                    <div name="arrowId3" className="arrow"></div>
                  </div>
                  <div className="arrowSliding delay5">
                    <div name="arrowId3" className="arrow"></div>
                  </div>
                </div>
              </Box>
            </div>

            <div id="pijl4">
              <Box checked={checked3} onClick={handleChange3}>
              
                <div id="arrowAnim7">
                  <div className="arrowSliding">
                    <div name="arrowId4" className="arrow2"></div>
                  </div>
                  <div className="arrowSliding delay1">
                    <div name="arrowId4" className="arrow2"></div>
                  </div>
                  <div className="arrowSliding delay2">
                    <div name="arrowId4" className="arrow2"></div>
                  </div>
                  <div className="arrowSliding delay3">
                    <div name="arrowId4" className="arrow2"></div>
                  </div>
                  <div className="arrowSliding delay4">
                    <div name="arrowId4" className="arrow2"></div>
                  </div>
                  <div className="arrowSliding delay5">
                    <div name="arrowId4" className="arrow2"></div>
                  </div>
                </div>
              </Box>
              </div>
              </Box>


            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "baseline",
                gridColumn: "4",
                gridRow: "4",
              }}
            >
              <div id="pijl5">
              <Box checked={checked3} onClick={handleChange3}>
              
                <div id="arrowAnim4">
                  <div className="arrowSliding">
                    <div name="arrowId5" className="arrow"></div>
                  </div>
                  <div className="arrowSliding delay1">
                    <div name="arrowId5" className="arrow"></div>
                  </div>
                  <div className="arrowSliding delay2">
                    <div name="arrowId5" className="arrow"></div>
                  </div>
                  <div className="arrowSliding delay3">
                    <div name="arrowId5" className="arrow"></div>
                  </div>
                  <div className="arrowSliding delay4">
                    <div name="arrowId5" className="arrow"></div>
                  </div>
                  <div className="arrowSliding delay5">
                    <div name="arrowId5" className="arrow"></div>
                  </div>
                </div>
          
              </Box>
          </div>
          <div id="pijl6">
              <Box  checked={checked3} onClick={handleChange3} >
              
                <div id="arrowAnim6">
                  <div className="arrowSliding">
                    <div name="arrowId6" className="arrow2"></div>
                  </div>
                  <div className="arrowSliding delay1">
                    <div name="arrowId6" className="arrow2"></div>
                  </div>
                  <div className="arrowSliding delay2">
                    <div name="arrowId6" className="arrow2"></div>
                  </div>
                  <div className="arrowSliding delay3">
                    <div name="arrowId6" className="arrow2"></div>
                  </div>
                  <div className="arrowSliding delay4">
                    <div name="arrowId6" className="arrow2"></div>
                  </div>
                  <div className="arrowSliding delay5">
                    <div name="arrowId6" className="arrow2"></div>
                  </div>
                </div>
              </Box>
              </div>
            </Box>
            <div className="containersIcons" style={{
                gridColumn: "3",
                gridRow: "5", zIndex:"1"}}>
                <Typography>Net</Typography>
                <Button checked={checked2} checked2={ checked3} onClick={handleChange4} sx={{height: { xs: 103, md: 147 },
                width: { xs: 103, md: 150 },boxShadow: 5, backgroundColor: "lightblue",borderRadius: "30%",}}>
            <OutletSharpIcon sx={{ height: { xs: 80, md: 100 },
                width: { xs: 80, md: 100 }, borderRadius: "30%",}}></OutletSharpIcon>
            </Button>
            </div>
          </Card>
          <Card elevation={5}
          id="BottomCard"
            sx={{
              width: "100%",
              fontFamily:"Roboto",
              padding: "3em",
              display:"none",
              height:"100%",
              borderRadius: "30px",
              marginBottom: "2em",
              alignSelf: "center",
              
              justifyContent: "space-evenly"
              
              }}>
          <Grow
              in={checked}
              style={{gridColumn: "1", row:"1", transformOrigin: '0 0 0', height:"83px", width:"30vw"}}
              {...(checked ? { timeout: 500 } : {})}
            >
            {icon2}
            </Grow>
          <Grow
              in={checked}
              style={{gridColumn: "2", row:"1", transformOrigin: '0 0 0', height:"83px", width:"30vw"}}
              {...(checked ? { timeout: 1000 } : {})}
            >
            {icon1}
            </Grow>
            <Box sx={{gridColumn: "1", gridRow: "2" ,height:"100%",display:"flex", flexDirection:"column"}}>
            <Grow
              in={checked2}
              style={{transformOrigin: '0 0 0', width:"30vw"}}
              {...(checked2 ? { timeout: 500 } : {})}
            >
            {icon3}
            </Grow>
            <Grow
              in={checked2}
              style={{transformOrigin: '0 0 0', width:"30vw"}}
              {...(checked2 ? { timeout: 1000 } : {})}
            >
            {icon4}
            </Grow>
            </Box>


            <Box sx={{gridColumn: "2", gridRow: "2" ,height:"100%",display:"flex", flexDirection:"column"}}>
            <Grow
              in={checked3}
              style={{transformOrigin: '0 0 0', width:"30vw"}}
              {...(checked3 ? { timeout: 500 } : {})}
            >
            {icon5}
            </Grow>
            <Grow
              in={checked3}
              style={{transformOrigin: '0 0 0', width:"30vw"}}
              {...(checked3 ? { timeout: 1000 } : {})}
            >
            {icon6}
            </Grow>
            </Box>
          </Card>
        </Container>
        
        
        {/* <Button onClick={() => addNewCard({title: "Unit X"})}>CLICK MEEE</Button> */}
        <Footer />
      </Stack>
    </div>
  );

  
}

export default Scenario1;
