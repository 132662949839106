import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import { Link, useLocation } from 'react-router-dom';

function getPageIndex(route) {
    switch (route) {
        case '/privacy': return 1;
        case '/': return 0;
        default: return 0;
    }
}

export default function Footer() {
    //   const [value, setValue] = React.useState(0);
    const location = useLocation();
    //console.log(location)
    const value = getPageIndex(location.pathname);



    return (
        <Box className="bottomNavigation">
            <BottomNavigation
                showLabels
                value={value}
            // onChange={(event, newValue) => {
            //   setValue(newValue);
            // }}
            >
                <BottomNavigationAction label="Home" icon={<HomeIcon />} component={Link} to="/"/>
                {/* <BottomNavigationAction label="Privacy Policy" icon={<PolicyIcon />} component={Link} to="/privacy"/> */}
            </BottomNavigation>
        </Box>
    );
}