import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import reportWebVitals from './__tests__/reportWebVitals';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
let theme = createTheme({

  typography: {
    fontSize: 16
  },

  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#ffab00',
    },
  },

  transitions: {
    duration: {
      enteringScreen: 1500,
      leavingScreen: 1000,
    }
  }
});


root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
