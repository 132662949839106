import * as React from 'react';
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// eslint-disable-next-line
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// eslint-disable-next-line
import { FormGroup, Rating, InputLabel, Select, MenuItem, css } from '@mui/material';
import CustomTextField from './CustomTextField';
// eslint-disable-next-line

import RemoveUnitCard from '../components/RemoveUnitCard';
// eslint-disable-next-line


export default function UnitCard({ id, onCardChange, removeElement }) {
    // eslint-disable-next-line
    const [ID, setID] = React.useState(id);
    // eslint-disable-next-line
    const [unitName, setUnitName] = React.useState("Unit " + id);
    const [telsysteem, setTelsysteem] = React.useState('enkelvoudig');
    const [unitType, setUnitType] = React.useState('appartement');
    const [heatingType, setHeatingType] = React.useState('gas');
    // eslint-disable-next-line
    const [name, setName] = React.useState('name')
    // eslint-disable-next-line
    const [familyMembers, setFamilyMembers] = React.useState(2);
    // eslint-disable-next-line
    const [ev, setEv] = React.useState(false);
    //const [solar, setSolar] = React.useState(false);
    const [contract, setContract] = React.useState(false);
    // eslint-disable-next-line
    const [evAmount, setEvAmount] = React.useState(0);
    // eslint-disable-next-line
    //const [solarAmount, setSolarAmount] = React.useState(0);
    // eslint-disable-next-line
    const [numberOfUnits, setNumberOfUnits] = React.useState(0);
    // eslint-disable-next-line
    const [validEvInput, setValidEvInput] = React.useState(true);
    // eslint-disable-next-line
    // const [validSolarInput, setValidSolarInput] = React.useState(true);
    // eslint-disable-next-line
    const [validNumberOfUnitsInput, setValidNumberOfUnitsInput] = React.useState(true);

    const handleUnitTypeChange = (event) => {
        setUnitType(event.target.value);
        onCardChange(ID, "unitType", event.target.value);
    };

    const handleHeatingTypeChange = (event) => {
        setHeatingType(event.target.value);
        onCardChange(ID, "heatingType", event.target.value);
    };

    // eslint-disable-next-line
    const handleTelsysteemChange = (event) => {
        setTelsysteem(event.target.value);
        onCardChange(ID, "telsysteem", event.target.value);
    };

    // eslint-disable-next-line
    const handleEvChange = (event) => {
        setEv(event.target.checked)
    }

    /*const handleSolarChange = (event) => {
    /*const handleSolarChange = (event) => {
        setSolar(event.target.checked)
    }*/


    const handleContractChange = (event) => {
        setContract(event.target.checked)
    }

    



    const [visible, setVisible] = useState(true);
    /*
    const removeElement = () => {
        setVisible((prev) => !prev);
        removeCard();

      };*/


    
    







    return (
        visible && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card id='mediacard' variant='outlined' className='mediacard'>
                    <Container sx={{ textAlign: "right", marginTop: "10px" }}>
                        <RemoveUnitCard
                            setVisible={setVisible}
                            ID={ID}
                            setID={setID}
                            removeElement={removeElement}>
                            
                        </RemoveUnitCard>
                    </Container>
                    <CardContent>
                        <FormControl size='large' sx={{ mb: '7px', width: "80%" }}>
                            <CustomTextField
                                cardID={ID}
                                textFieldID="name"
                                onCardChange={onCardChange}
                                onSettingsChange={null}
                                adornment=""
                                defaultValue={unitName}
                                validation="name"
                                label="Naam"
                            />
                        </FormControl>
                        <FormControl size='large' sx={{ mb: '7px', width: "80%" }}>
                            <FormGroup>
                                <InputLabel id="unit-type-select-label">Type Unit</InputLabel>
                                <Select
                                    labelId="unit-type-select-label"
                                    id="unit-type-select"
                                    value={unitType}
                                    label="Type unit"
                                    onChange={handleUnitTypeChange}
                                >
                                    <MenuItem value="appartement">Appartement</MenuItem>
                                    <MenuItem value="gemeenschappelijk">Gemene delen</MenuItem>
                                    {/* <MenuItem value="commercieel">Commercieel</MenuItem> */}
                                </Select>
                            </FormGroup>
                        </FormControl>

                        {unitType === "appartement" ?
                            <FormControl size='large' sx={{ width: "80%" }}>
                                <FormGroup>
                                    {/* <FormLabel id="heating-type-label">Verwarming</FormLabel> */}
                                    <InputLabel id="heating-type-select-label">Verwarming</InputLabel>
                                    <Select
                                        labelId="heating-type-select-label"
                                        id="heating-type-select"
                                        value={heatingType}
                                        label="Verwarming"
                                        onChange={handleHeatingTypeChange}
                                    >
                                        <MenuItem value="gas">Gas</MenuItem>
                                        <MenuItem value="warmtepomp">Warmtepomp</MenuItem>
                                        <MenuItem value="elektrisch">Direct elektrisch</MenuItem>
                                    </Select>
                                </FormGroup>
                                
                                <FormLabel id="gezinsleden-label">Aantal gezinsleden</FormLabel>
                                <Container sx={{justifyContent:"center",display:"flex"}}>
                                <FormGroup aria-labelledby='gezinsleden-label'>
                                    <Rating
                                        icon={<PersonIcon />}
                                        emptyIcon={<PersonOutlineIcon />}
                                        defaultValue={2}
                                        
                                        sx={{ margin: '5px' }}
                                    />
                                    
                                </FormGroup>
                                <Typography id="plus5Label" style={{fontWeight:"900",fontSize:"12px",color:"rgba(0, 0, 0, 0.3)"}}>5+</Typography>
                                </Container>

                            </FormControl>
                            : null}

                        <FormControl size='large' sx={{ width: "80%" }}>
                            <FormLabel id="telsysteem-label">Telsysteem</FormLabel>
                            <RadioGroup
                                aria-labelledby="telsysteem-label"
                                name="telsysteem-radio-group"
                                value={telsysteem}
                                onChange={handleTelsysteemChange}
                            >
                                <FormControlLabel value="enkelvoudig" control={<Radio />} label="Enkelvoudig" />
                                <FormControlLabel value="dag-nacht" control={<Radio />} label="Dag/nacht" />
                                {/* <FormControlLabel value="dynamisch" control={<Radio />} label="Dynamisch" /> */}
                            </RadioGroup>


                            <FormLabel id="installatie-label">Je installaties</FormLabel>
                            <FormGroup
                                aria-labelledby='installatie-label'
                            >

                                {/*solar ?
                                <CustomTextField 
                                    cardID={ID}
                                    textFieldID="solarAmount"
                                    onCardChange={onCardChange}
                                    adornment="kWp"
                                    defaultValue="0"
                                    validation="float"
                                />
                            : null*/}
                                <FormControlLabel checked={ev} onChange={handleEvChange} control={<Checkbox default size="small" />} label="Elektrische wagens" />

                                {ev ?
                                    <CustomTextField
                                        cardID={ID}
                                        textFieldID="evAmount"
                                        onCardChange={onCardChange}
                                        onSettingsChange={null}
                                        adornment="laadpunten"
                                        defaultValue="0"
                                        validation="int"
                                    />
                                    : null}
                            </FormGroup>


                            <FormLabel id="energy-contract-label">Jouw energiecontract</FormLabel>
                            <FormGroup
                                aria-labelledby='energy-contract-label'
                            >

                                <FormControlLabel checked={contract} onChange={handleContractChange} control={<Checkbox default size="small" />} label="Ik ken mijn energiecontract" />
                                {(contract && telsysteem === "enkelvoudig")?

                                    <CustomTextField
                                        cardID={ID}
                                        textFieldID="dayPrice"
                                        onCardChange={onCardChange}
                                        onSettingsChange={null}
                                        adornment="€"
                                        defaultValue="0.4"
                                        validation="float"
                                        label="Afnametarief"
                                    /> : null}
                                {(contract && telsysteem === "enkelvoudig")?
                                    <CustomTextField
                                        cardID={ID}
                                        textFieldID="dayInjectionPrice"
                                        onCardChange={onCardChange}
                                        onSettingsChange={null}
                                        adornment="€"
                                        defaultValue="0.1"
                                        validation="float"
                                        label="Injectietarief"
                                    /> : null}
                                

                                    {(contract && telsysteem === "dag-nacht")?

                                    <CustomTextField
                                        cardID={ID}
                                        textFieldID="dayPrice"
                                        onCardChange={onCardChange}
                                        onSettingsChange={null}
                                        adornment="€"
                                        defaultValue="0.4"
                                        validation="float"
                                        label="Afnametarief dag"
                                    /> : null}

                                    {(contract && telsysteem === "dag-nacht")?
                                    <CustomTextField
                                        cardID={ID}
                                        textFieldID="dayInjectionPrice"
                                        onCardChange={onCardChange}
                                        onSettingsChange={null}
                                        adornment="€"
                                        defaultValue="0.1"
                                        validation="float"
                                        label="Injectietarief dag"
                                    /> : null}

                                {(contract && telsysteem === "dag-nacht") ?

                                    <CustomTextField
                                        cardID={ID}
                                        textFieldID="nightPrice"
                                        onCardChange={onCardChange}
                                        onSettingsChange={null}
                                        adornment="€"
                                        defaultValue="0.3"
                                        validation="float"
                                        label="Afnametarief nacht"
                                    /> : null}
                                {(contract && telsysteem === "dag-nacht") ?
                                    <CustomTextField
                                        cardID={ID}
                                        textFieldID="nightInjectionPrice"
                                        onCardChange={onCardChange}
                                        onSettingsChange={null}
                                        adornment="€"
                                        defaultValue="0.05"
                                        validation="float"
                                        label="Injectietarief nacht"
                                    /> : null}
                                {contract?
                                    <CustomTextField
                                        cardID={ID}
                                        textFieldID="energyComponent"
                                        onCardChange={onCardChange}
                                        onSettingsChange={null}
                                        adornment="€"
                                        defaultValue="0.25"
                                        validation="float"
                                        label="Energiecomponent"
                                    /> : null}


                            </FormGroup>

                            <FormLabel id="number-of-units-label">Aantal van deze units</FormLabel>
                            <FormGroup
                                aria-labelledby='number-of-units-label'
                            >
                                <CustomTextField
                                    cardID={ID}
                                    textFieldID="numberOfUnits"
                                    onCardChange={onCardChange}
                                    onSettingsChange={null}
                                    adornment="units"
                                    defaultValue="1"
                                    validation="int"
                                />
                            </FormGroup>


                            <FormLabel id="division-key-share-label">Verdeelsleutel per unit
                            <Tooltip title="De verdeelsleutel bepaalt welke unit welk aandeel van de hernieuwbare energie ter beschikking krijgt. Je kan ervoor kiezen om iedereen een gelijk deel te geven, of de grotere verbruikers een iets groter aandeel. Meer informatie over mogelijke verdeelsleutels vind je hier: https://www.fluvius.be/nl/groene-energie/delen-en-verkopen-van-energie/hoe-wordt-de-injectie-verdeeld-bij-energiedelen">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                                </Tooltip>
                            </FormLabel>
                            <FormGroup
                                aria-labelledby='division-key-share-label'
                            >
                                <CustomTextField
                                    cardID={ID}
                                    textFieldID="divisionKeyShare"
                                    onCardChange={onCardChange}
                                    onSettingsChange={null}
                                    adornment="/1000"
                                    defaultValue="0"
                                    validation="int"
                                />
                                
                            </FormGroup>


                            

                        </FormControl>
                    </CardContent>
                </Card>
            </Grid>)


    );

}