
import '../assets/css/App.css';
import Footer from '../components/Footer';
import * as React from "react";
import Stack from '@mui/material/Stack'
import { Grid, Typography } from '@mui/material';
import Navbar from '../components/Navbar';
import UnitCard from '../components/UnitCard';
import { Container } from '@mui/system';
import AddUnitCard from '../components/AddUnitCard';
import { useState } from 'react';
import CalculateIcon from '@mui/icons-material/Calculate';
import Fab from '@mui/material/Fab';
import SettingsField from '../components/SettingsField';
import { useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function Home() {
  // eslint-disable-next-line
  const [output, setOutput] = useState([]);
  const [cardList, setCardList] = useState([]);
  // const [settingsList, setSettingsList] = useState([]);
  const [settingsList, setSettingsList] = useState({"totalSolarAmount": 10, "totalSolarPrice": 1200, "adminCost": 100});
  const [lastID, setLastID] = useState(0);
  const [isPending, setIsPending] = useState(false);
  // eslint-disable-next-line
  const [isDeleted, setIsDeleted] = useState(false);
  const [isValidDivisionKeyInput, setisValidDivisionKeyInput] = useState(true);
  const [duplicateNameInput, setDuplicateNameInput] = useState(true);
  const [lessThan2Units, setlessThan2Units] = useState(true);
  // eslint-disable-next-line
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  // const jsonString = ""
  const addNewCard = (newCard) => {
    setCardList(cardList => cardList.concat(newCard));
    setOutput(output => output.concat(newCard))
  }

  const onTelSysteemChange = (value, id) => {
    //console.log(value);
    //console.log("Telsysteem " + id)
  }

  const removeElement = (id) => {
    setVisible((prev) => !prev);
    cardList.pop(id);
  };



  const onSettingsChange = (key, value) => {
    let temp = settingsList;
    temp[key] = value;
    setSettingsList(temp);
    // console.log(settingsList);
  }

  const onCardChange = (id, key, value) => {
    let temp = cardList;
    // let tempCard = //
    temp.find((card, i) => {
      if (card.id === id) {
        temp[i][key] = value;
        return true; // stop searching
      }
      return false;
    });
    setCardList(temp);
    setisValidDivisionKeyInput(true);
    //console.log(cardList);
  }



  const validateInput = (cardList) => {
    // Check if division key shares add up to 1000
    // Check if there is a duplicate name in the cardList
    let divisionKeyTotal = 0
    let duplicateName = false
    let lessThan2Units = false
    let valuesSoFar = []
    for (var i in cardList) {
      // console.log(cardList[i])
      divisionKeyTotal = divisionKeyTotal + parseInt(cardList[i].divisionKeyShare * cardList[i].numberOfUnits)
      //console.log(valuesSoFar)
      if (valuesSoFar.includes(cardList[i].name)) {
        duplicateName = true
        // console.log("DUPLICATE FOUNDDDDD")
      }
     
      valuesSoFar.push(cardList[i].name)
    }
    
    
    if(Object.keys(cardList).length < 2){
      lessThan2Units = true
    }else{
      lessThan2Units = false
    }
    if(lessThan2Units === true){
      setlessThan2Units(false)
    } else {
      setlessThan2Units(true)
    }
    // console.log(divisionKeyTotal)
    if (divisionKeyTotal !== 1000) {
      setisValidDivisionKeyInput(false)
    }
    if (duplicateName === true) {
      setDuplicateNameInput(false)
    }

    if ((divisionKeyTotal !== 1000) || (duplicateName === true) || (lessThan2Units === true) ) {
      return false
    } else {
      return true
    }
    

  }

  function checkResponseForErrors(response){
    //console.log(Object.keys(response[0]))
    if(Object.keys(response[0])[0]==='error'){
      document.getElementById("innerWord").innerHTML = Object.values(response[0])[0]
      document.getElementById("innerWord").style.color = "white"
      document.getElementById("innerWord").parentElement.style.height = "80px"
      return true
    }
    else{return false}
    

  }
  
  
  // const calculateUnits = () => {

  // var listSharedUnits = 0

  // for(var unit=0; unit<cardList.length; unit++){
  //   if((Object.values(cardList)[unit])["unitType"]==="gemeenschappelijk"){
  //     listSharedUnits = parseInt(listSharedUnits) + (Object.values(cardList)[unit])["numberOfUnits"]
  //   }
  // }

  //   let numberOfUnits = 0
  //   for(var row in cardList){
  //     numberOfUnits = parseInt(numberOfUnits) + parseInt(Object.values(Object.values(cardList)[row])[13])
  //   }
  //   if(listSharedUnits === 0){console.log(1);return numberOfUnits*0.2}
  //   else if(listSharedUnits <= 10){console.log(2);return numberOfUnits*0.73}
  //   else if(10<listSharedUnits){console.log(3);return numberOfUnits*1.12}
    
  // }
  
  
         


  const handleSubmit = () => {
    

    
    
    let outputObject = Object.assign({}, settingsList, cardList);
    // console.log(JSON.stringify(outputObject));

    if (!validateInput(cardList)) { return }

    //console.log(cardList);

    
    // document.getElementById("estimatedTime").innerHTML = "Geschatte tijd..: " + calculateUnits().toFixed(2) + "s"
    setIsPending(true);
    var start = Date.now()

    // fetch('http://localhost:7071/api/Optishare-functions', {
    fetch('https://think-e-optishare-app.azurewebsites.net/api/Optishare-functions', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(outputObject)
    })
      .then(response => response.json())
      .then(data => {
        if(checkResponseForErrors(data)===false){
          setIsPending(true);
          console.log(data)
          var end = Date.now()
          console.log((end - start)/1000)
          navigate('/scenario1', {
            state: data
          })
        }else{
          setIsPending(true);
        }
        
      })
      

  }




  return (
    <div className="App">
      {/* <Banner></Banner> */}
      <Stack spacing={3}>
        <Navbar />
        <Container maxWidth="100%">
        
          <Backdrop
            sx={{display:"flex", flexDirection:"column",gap:"100px", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isPending}
            
          >
            
            <CircularProgress color="secondary" size='6rem'/>
            <Container sx={{display:"flex", flexDirection:"column", width:"350px"}}>
            <p id="estimatedTime" className="estimatedTime" style={{marginLeft:"10px",alignSelf:"flex-start"}}></p>
            <Container className='containerWord' sx={{color:"secondary",justifyContent:"center",paddingLeft:"10px",paddingRight:"10px",
            paddingTop:"11px",height:"50px",borderRadius:"30px",border:"2px solid #ffab00",background: "#59595C"}}>
            <div id='innerWord' style={{display:"flex"}}>
            <p className='word' style={{color:"#ffab00",opacity:"0",position: "absolute"}}>Samenstellen van gegevens...</p>
            <p className='word' style={{color:"#ffab00",opacity:"0",position: "absolute"}}>Gegevens berekenen...</p>
            <p className='word' style={{color:"#ffab00",opacity:"0",position: "absolute"}}>Ontwerp afwerken...</p>
            </div>
            </Container>
            </Container>
            <Typography sx={{position:"absolute",bottom:"0",alignSelf:"flex-start",width:"500px", height:"65px", backgroundColor:"rgba(0,0,0,0.3)", borderRadius:"10px", fontSize:"12px", padding:"5px"}}>Disclaimer: De auteurs hebben deze simulatie zo compleet en nauwkeurig mogelijk samengesteld, op basis van gegevens ingevuld door de gebruiker. Aan deze resultaten kunt u geen rechten ontlenen.</Typography>
          </Backdrop>

          <SettingsField onSettingsChange={onSettingsChange} ></SettingsField>
          <Grid container spacing={5} justifyContent='space-evenly'>
            
            {cardList.map((card, i) => (
              
              <UnitCard {...card} key={i}
                onTelSysteemChange={onTelSysteemChange}
                onCardChange={onCardChange}
                removeElement={removeElement}
              />
            ))}
            <AddUnitCard
              addNewCard={addNewCard}
              lastID={lastID}
              setLastID={setLastID}
            />
          </Grid>
        </Container>

        <Container maxWidth="100%">
          {!isValidDivisionKeyInput && lessThan2Units && <Alert severity="error" sx={{ ml: "20%", mr: "20%", justifyContent: "center" }}>
            <AlertTitle>Fout</AlertTitle>
            Zorg ervoor dat de som van de verdeelsleutels uitkomt op <strong>1000</strong>
          </Alert>}
          </Container>
          <Container maxWidth="100%">
          {!duplicateNameInput && <Alert severity="error" sx={{ ml: "20%", mr: "20%", justifyContent: "center" }}>
            <AlertTitle>Fout</AlertTitle>
            Zorg ervoor dat de namen van de units <strong>uniek</strong> zijn
          </Alert>}
          {!lessThan2Units && <Alert severity="error" sx={{ ml: "20%", mr: "20%", justifyContent: "center" }}>
            <AlertTitle>Fout</AlertTitle>
            Zorg ervoor dat er <strong>minstens</strong> 2 units zijn
          </Alert>}
        </Container>


        <Container maxWidth="100%">



          {!isPending && <Fab color="secondary" aria-label="simulate" variant='extended' size='large' onClick={handleSubmit}>
            <CalculateIcon />
            Simuleer!
          </Fab>}
          {isPending && <Fab disabled color="secondary" aria-label="simulate" variant='extended' size='large'>
            <CalculateIcon />
            Aan het simuleren...
          </Fab>}
        </Container>
        <Footer />
      </Stack>

    </div>
  );
}

export default Home;